<template>
    <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    id="finalizat"
    width="600"
    :visible.sync="showPopup">
    <!-- {{Info}} -->
    <div class="container">
        <div class="left-side">            
            <div class="main">                     
                <img src="../assets/done.png">
                <h1>Programare confirmata</h1>
                <h3>Un email de confirmare a fost trimis catre dumneavoastra</h3>
            </div>
            <div class="footer">
                <h1>Intrebari?</h1>
                <h3>Suna la 0754 00 44 00</h3>
            </div>
        </div>
        <div class="right-side">
            <div class="header">
                <p>Programare confirmata</p>
                <i @click="inchide_fereastra()" class="el-icon-close"></i>
            </div>
            <div class="main">
                <div class="head">
                    <div class="id-programare">
                    <h5>PROGRAMARE #</h5>
                    <h1>{{Info.IdProgramare}}</h1>
                    <!-- <button @click="go_to_calendar()"><i class="el-icon-date"></i> Adauga in calendar</button> -->
                    <button @click="generate_ics_data()"><i class="el-icon-date"></i> Adauga in calendar</button>
<!-- 
                        <google-calendar id="google-calendar">
                        <i class="fa fa-google"></i> Add to Google calendar
                        </google-calendar>

                        <microsoft-calendar id="microsoft-calendar">
                        <i class="fa fa-windows"></i> Add to Microsoft live calendar
                        </microsoft-calendar>

                        <office365-calendar id="office365-calendar">
                        <i class="fa fa-windows"></i> Add to Office365 outlook calendar
                        </office365-calendar> -->
                </div> 
                    <div @click="show_qr()" class="qr-code">
                        <!-- <i class="el-icon-menu"></i> -->
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 308.667 308.667" style="enable-background:new 0 0 308.667 308.667;" xml:space="preserve">
                        <g>
                            <g>
                                <rect x="38.073" y="38.073" style="fill:#010002;" width="47.592" height="47.592"/>
                                <rect x="38.073" y="223.002" style="fill:#010002;" width="47.592" height="47.592"/>
                                <path style="fill:#010002;" d="M0,163.852v21.076v13.598v110.141h123.739v-66.629h28.555v30.595v2.04v32.634h34.674v-28.555
                                    h27.195v28.555h34.674v-32.634h25.156v32.634h34.674v-34.674h-32.634v-32.634h-34.674v32.634H218.58v-67.308h-31.612V176.77
                                    h32.634v32.634h34.674v-21.756h19.717v14.278v18.357v16.317h34.674v-16.317v-18.357v-16.317h-21.756v-30.595h21.756v-31.275
                                    v-3.399V0H184.928v0.228h-32.634v37.845h-28.555V0H0v109.461v14.278v20.396h21.756v19.717H0z M101.982,286.911H21.756v-80.226
                                    h80.226V286.911z M154.333,209.404h29.578v30.595h-29.578V209.404z M154.333,144.135h27.533v30.595h-27.533V144.135z
                                    M273.993,152.974h-18.7v-10.878h-68.326v-18.357h87.025V152.974z M206.684,21.756h80.226v80.226h-80.226V21.756z
                                    M184.928,109.461h-30.595v-67.76h30.595V109.461z M152.294,142.096h-28.468v-30.595h28.468V142.096z M21.756,21.756h80.226
                                    v80.226H21.756V21.756z M89.152,123.739v20.396h30.508v32.634h32.634v30.595h-28.555v-22.436H34.674v-8.159h19.717h2.04h32.634
                                    v-34.674H56.43h-2.04H34.674v-18.357H89.152z"/><rect x="223.002" y="38.073" style="fill:#010002;" width="47.592" height="47.592"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                    <p>Click pentu cod QR adagare calendar</p>
                    </div>
                </div>
                <div class="component">
                    <div class="title">
                        <h1>Detaliile programarii</h1>
                    </div>
                    <div class="container">
                        <div class="element">
                            <div class="titlu-element">
                                DATA:
                            </div>
                            <div class="content-element">
                                {{get_date(Info.Ora)}}
                            </div>
                        </div>
                        <div class="element">
                            <div class="titlu-element">
                                ORA:
                            </div>
                            <div class="content-element">
                                {{get_hour(Info.Ora)}}
                            </div>
                        </div>
                        <div class="element">
                            <div class="titlu-element">
                                NUMAR AUTO:
                            </div>
                            <div class="content-element">
                                {{Info.Numar}}
                            </div>
                        </div>
                        <div class="element">
                            <div class="titlu-element">
                                SERVICIU:
                            </div>
                            <div class="content-element">
                                {{Info.Serviciu.nume}}
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="component">
                    <div class="title">
                        <h1>Date de contact</h1>
                    </div>
                    <div class="container">
                        <div class="element">
                            <div class="titlu-element">
                                NUME:
                            </div>
                            <div class="content-element">
                                {{Info.Nume}} {{Info.Prenume}}
                            </div>
                        </div>
                        <div class="element">
                            <div class="titlu-element">
                                TELEFON:
                            </div>
                            <div class="content-element">
                                {{Info.Telefon}}
                            </div>
                        </div>
                        <div class="element">
                            <div class="titlu-element">
                                EMAIL:
                            </div>
                            <div class="content-element">
                                {{Info.Email}}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    </el-dialog>
</template>

<script>
    import moment from 'moment';
    import ics, {createEvent} from 'ics';
    import google_link_generator from 'generate-google-calendar-link';
    export default {
    name: 'Finalizare',
    data() {
        return {
            Info: {
                IdProgramare: '',
                Nume: '',
                Prenume: '',
                Ora: '',
                Email: '',
                Telefon: '',
                Serviciu: {
                    nume: '',
                    pret: '',
                }
            },
            showPopup: false,
        }
    },
    methods: {

        show_qr: function() {
            let qr_url = "https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=";
            let calendar_url = this.generate_google_calendar_url();
            let final_ur = qr_url + encodeURIComponent(calendar_url);
            this.$alert(`<center><img src="${final_ur}" /></center>`, "Cod QR pentru adaugarea programarii in calendar", {
            dangerouslyUseHTMLString: true
        });
        },

        go_to_calendar: function() {
            let url = this.generate_google_calendar_url();
            // this.$router.go(url);
            window.open(url, '_blank').focus();
        },

        generate_ics_data() {
            let start = moment(this.Info.Ora_start).format('YYYY-M-D-H-m').split('-').map(num => Number(num));
            let stop = moment(this.Info.Ora_stop).format('YYYY-M-D-H-m').split('-').map(num => Number(num));
            let title = 'Programare service Anvelope.ro';
            let description = 'Programare service Anvelope.ro';
            let location_url = 'https://www.google.com/maps/place/Bulevardul+Pipera+1e,+Voluntari+077190/@44.4899694\,26.1202396\,16.99z/data=!4m5!3m4!1s0x40b20283c702c4a7:0xb9d7802694d861b4!8m2!3d44.4901888!4d26.1201087';
            let location = 'Bulevardul Pipera 1E, Voluntari 077190';
            let lat = 44.49037248694056;
            let lon = 26.120484209253885;
            let status = 'CONFIRMED';
            let alarm = [{action: 'audio',
                description: 'Reminder',
                trigger: {hours:1,minutes:0,before:true},
                repeat: 2,
                attachType:'VALUE=URI',
                attach: 'Glass'}];
            let website = "https://programari.anvelope.ro/";
            let event = {
                start: start,
                end: stop,
                title: title,
                description: description,
                location: location,
                geo: {lat: lat, lon: lon},
                status: status,
                alarms: alarm,
                url: website
            }

            createEvent(event, (error, value) => {
                if (error) {
                    console.log(error);
                    return;
                }

                // console.log(value);
                var element = document.createElement('a');
                element.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(value);
                element.download = 'Calendar.ics';
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            });


        },

        generate_google_calendar_url: function() {
            
            // let location = "Bucharest";
            let location = 'Bulevardul Pipera 1E, Voluntari 077190';
            let location_url = 'https://www.google.com/maps/place/Bulevardul+Pipera+1e,+Voluntari+077190/@44.4899694\,26.1202396\,16.99z/data=!4m5!3m4!1s0x40b20283c702c4a7:0xb9d7802694d861b4!8m2!3d44.4901888!4d26.1201087';
            let text = "Programare Service";
            let start = new Date(this.Info.Ora_start);
            let stop = new Date(this.Info.Ora_stop);

            let url = google_link_generator({
                start: start,
                end: stop,
                title: text,
                location: location,
                location_url: location_url,
                details: ""
            });

            return url.href;


        },

        get_hour: function(data) {
            return moment(data).format("HH:mm");
        },

        get_date: function(data) {
            let luna = moment(data).format('MM');
            let zi = moment(data).format('DD');
            let an = moment(data).format('YYYY');

            luna = this.get_nume_luna(luna);
            return `${zi} ${luna}, ${an}`;
        },

        get_nume_luna: function(luna) {
            var nume = '';
                      switch (luna) {
                        case "01":
                        nume = "Ianuarie";
                        break;

                        case "02":
                        nume = "Februarie";
                        break;

                        case "03":
                        nume = "Martie";
                        break;

                        case "04":
                        nume = "Aprilie";
                        break;

                        case "05":
                        nume = "Mai";
                        break;

                        case "06":
                        nume = "Iunie";
                        break;

                        case "07":
                        nume = "Iulie";
                        break;

                        case "08":
                        nume = "August";
                        break;

                        case "09":
                        nume = "Septembrie";
                        break;

                        case "10":
                        nume = "Octombrie";
                        break;

                        case "11":
                        nume = "Noiembrie";
                        break;

                        case "12":
                        nume = "Decembrie";
                        break;
                    }
                    return nume;   
        },

        show_me: function(serviciu, data, idProgramare) {
            this.Info = {};
            this.showPopup = true;
            this.Info = data;
            this.Info.Serviciu = serviciu;
            this.Info.IdProgramare = idProgramare;
        },

        inchide_fereastra: async function() {
            this.showPopup = false;
            await this.$router.push({name: 'home'}).catch(err => {});
            await this.$router.go(0);
        },

        // format_email: function(email) {
        //     let parts = email.split("@");
        //     let first_part = parts[0];
        //     let new_fisrt_part = first_part.slice(0, 3) + first_part.slice(3, first_part.lenght).split('').map(s => s = '*').join('');
        //     return new_fisrt_part + '@' + parts[1];
        // }
    }
    
    }
</script>

<style lang="less">
    @gri: #F1F2F2;
    @gri_inchis: #8e97b3;
    @albastru_transparent:rgba(76, 100, 232, 0.445);
    @albastru: #4C63E8;
    @verde: #4C63E8;
    @rosu-hover: #F7E8E8;
    @rosu: #ec7376;
    @rosu_deschis: #F7E8E8;
    @galben: #FEF6CB;
    @galben_wallpaper: #FCFEF1;
    @galben_inchis: #E1D588;
    @liber: #caffc4;
    @liber_text: #185210;
    @ocupat: #f3f5f9;
    @ocupat_text: #b3bbca;
    @zi_indisponibila: #f7e9e9;
    @zi_indisponibila_text: #752f2f;
    @ruginiu: #8E291A;


    .dropdown {
        position: relative;
        display: inline-block;

    }
    a {
        text-decoration: none;
        color: @albastru
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: white;
        min-width: 300px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        padding: 12px 16px;
        border-radius: 3px;
        z-index: 1;
        
        }

    .dropdown:hover .dropdown-content, .dropdown:focus .dropdown-content {
        display: block;
    }

    @media only screen and (max-width: 768px) {
        .el-dialog {
            width: 90% !important;
        }
        .left-side {
            display: none;
        }
        .right-side {
            width: 100% !important;
        }
        .head {
            justify-content: flex-start !important;
        }
        .qr-code {
            display: none !important;
            p {
                text-align: center;
            }
        }
    }

 .el-dialog__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;

    }

    .v-modal {
        height: 100vh;
    }

    #finalizat .el-dialog {
        overflow: hidden;
        padding: 0;
        height: 70vh;
        border-radius: 5px;
    }
    .el-dialog__header {
        display: none;
    }
    .el-dialog__body {
        height: 100%;
        padding: 0;
    }

    .container {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100% !important;

        display: flex;
        // justify-content: center;
        flex-direction: row;
       .left-side {
            width: 30%;
            border-right: 1px solid @gri;

        .main {
            padding: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80%;
            flex-direction: column;
            font-weight: 800;
            animation: fadeIn 1s;
            h1 {
                font-size: 22px;
                color: @albastru;
                text-align: center;
            }

            h3 {
                text-align: center;
                word-break:normal;
                font-size: 18px;
                color: @gri_inchis;
            }
        }

        .footer {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 20%;
            flex-direction: column;
            font-weight: 800;

            h1 {
                font-size: 18px;
                color: @albastru;
            }

            h3 {
                font-size: 14px;
                color: @gri_inchis;
            }
        }
       }
       .right-side {
            width: 70%;
            .header {
                position: sticky;
                height: 10%;
                border-bottom: 1px solid @gri;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 15px;
                p {
                    margin: 0;
                    color: @albastru;
                    font-size: 20px;
                    font-weight: 600;
                }
                i {
                    cursor: pointer;
                    font-weight: 500;
                }
            }
            .main {
                overflow: auto;
                height: 90%;
                padding: 30px;
                .head {
                    margin-bottom: 30px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .id-programare {
                        width: 70%;
                        h5 {
                            font-size: 14px;
                            color: @gri_inchis;
                        }
                        h1 {
                            color: black;
                            font-size: 27px;
                            font-weight: 600;
                        }

                        button {
                            color: @ruginiu;
                            border: 1px solid @ruginiu;
                            padding: 3px;
                            font-weight: 500;
                            border-radius: 5px;
                        }
                    }
                    .qr-code {
                        width: 30%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        cursor: pointer;
                        svg {
                            // font-size: 60px;
                            width: 70px;
                            color: black;
                            margin-bottom: 10px;
                        }
                        p {
                            color: black;
                            font-size: 12px;
                            text-decoration: underline;
                            text-align: center;
                            word-break: normal;
                        }
                    }
                }
                .component {
                    margin: 10px 0;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    .title {
                        width: 100%;
                        border-bottom: 1px solid @albastru;
                        h1 {
                            color: @albastru;
                            font-size: 22px;
                        }
                    }
                    .container {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: wrap;
                        .element {
                            margin: 5px 0;
                            width: 50%;
                            border-bottom: 1px solid @gri;
                            .titlu-element {
                                font-size: 12px;
                                font-weight: 600;
                                color: @gri_inchis;
                            }
                            .content-element {
                                color: black;
                                font-size: 14px;
                                font-weight: 600;

                            }
                        }
                        
                    }
                }
            }
       }
        }
</style>
